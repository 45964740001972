import {
  FieldTypes,
  NumericFieldLimits,
  NumericFieldLimitsHumanReadable,
} from "@mixins/fields/types"
import StaticHelper from "@mixins/static-helpers"

const SelectionTypesForImport = [
  FieldTypes.USER,
  FieldTypes.DROPDOWN,
  FieldTypes.RADIO,
]

const flatfileUseCases = {
  USERS: 0,
  COMPANIES: 1,
}

const flatfileSithOptions = {
  USERS: {
    type: flatfileUseCases.USERS,
    showFilters: true,
    enableExport: true,
  },
  COMPANIES: {
    type: flatfileUseCases.COMPANIES,
    showFilters: false,
    enableExport: false,
  },
}

const addCompaniesConfig = {
  type: "Companies",
  slug: "companies",
  title: "Import Company Data",
  allowInvalidSubmit: false,
  managed: true,
  styleOverrides: {
    primaryButtonColor: "#1C1836",
  },
  allowCustom: false,
  disableManualInput: false,
  maxRecords: 5000,
}
const addUsersConfig = {
  type: "Users",
  slug: "users",
  title: "Import Users",
  allowInvalidSubmit: false,
  managed: true,
  styleOverrides: {
    primaryButtonColor: "#1C1836",
  },
  allowCustom: false,
  disableManualInput: false,
  maxRecords: 100,
}

const userFields = [
  {
    label: "Name",
    key: "name",
    description: "User's full name - Required",
    validators: [{ validate: "required" }],
  },
  {
    label: "Email",
    key: "email",
    description: "User's email address - Required",
    validators: [{ validate: "required" }, { validate: "unique" }],
  },
  {
    label: "Title",
    key: "title",
    description: "User's job title",
  },
  {
    label: "Username",
    key: "username",
    description: "User's username - Required",
    validators: [{ validate: "required" }, { validate: "unique" }],
  },
  {
    label: "Password",
    key: "password",
    description:
      "This field does not affect existing users. Passwords will be automatically provisioned for new users where this field is left blank. Passwords must contain at least 8 characters, one uppercase character, one lowercase character, one numerical digit, and one non-alphanumeric character.",
  },
  {
    label: "Permissions",
    key: "permissions",
    description:
      "0 = General User, 1 = Group Admin, 2 = Network Admin. Should be an integer 0-2, and you can only give permissions up to your accounts admin level.",
  },
]

function keyFor(field) {
  if (field.source === 0 && ["kt_name", "kt_url"].includes(field.attribute)) {
    return field.attribute === "kt_name" ? "name" : "url"
  } else {
    return `${field.hid}`
  }
}

function typeFor(field) {
  return SelectionTypesForImport.includes(field.use_case) ? "select" : "string"
}

function optionsFor(field, users) {
  if (SelectionTypesForImport.includes(field.use_case)) {
    if (field.use_case === FieldTypes.USER) {
      return _.map(users, (user) => {
        return { value: user.id, label: user.name || user.email }
      })
    } else {
      return _.map(field.choices, (choice) => {
        return { value: choice, label: choice }
      })
    }
  } else {
    return []
  }
}

function descriptionFor(field) {
  if (field.attribute === "kt_name") {
    return "Company name"
  } else if (field.attribute === "kt_url") {
    return "Company url"
  } else if (field.use_case === FieldTypes.TEXT) {
    return "Text field"
  } else if (field.use_case === FieldTypes.RANGE) {
    return "Range field"
  } else if (field.use_case === FieldTypes.DATE) {
    return "Date field"
  } else if (field.use_case === FieldTypes.DROPDOWN) {
    return "Dropdown field"
  } else if (field.use_case === FieldTypes.USER) {
    return "User field"
  } else if (field.use_case === FieldTypes.CHECKBOX) {
    return "Checkbox field"
  } else if (field.use_case === FieldTypes.RADIO) {
    return "Radio field"
  } else if (field.use_case === FieldTypes.NUMBER) {
    return "Number field"
  } else if (field.use_case === FieldTypes.TAG) {
    return "Tag field"
  }
}

function validatorsFor(field) {
  // Required fields
  if (["kt_name", "kt_url"].includes(field.attribute)) {
    return [{ validate: "required" }]
  }
  return []
}

// This is legacy code that pulled the checkbox options out of the gfc
// and presented them to the user for mapping. It only allows for 1:1 mapping
// and was removed when we opted to let the user import multiple options.

const flatMapCheckboxChoices = function (choices) {
  return _.reduce(
    choices,
    (arr, choice) => {
      // arr.push({ value: choice.hid, label: choice.choice })
      if (choice.choices.length) {
        if (choice.choice) arr.push(choice.choice)
        return arr.concat(flatMapCheckboxChoices(choice.choices))
        // Added to support validations for multi-checkboxes
      } else {
        arr.push(choice.choice)
      }
      return arr
    },
    []
  )
}

function detectDuplicateCheckboxChoices(checkboxChoices) {
  let choices = new Set()
  let dupes = new Set()
  checkboxChoices.forEach((choice) => {
    // if the choice is already in the choices set, then we have a dupe
    if (choices.has(choice)) dupes.add(choice)
    // Always add the choice to the choices set. Sets only allow the same value
    // to be entered once, so adding a duplicate will not cause duplicates
    choices.add(choice)
  })
  return dupes
}

function configureField(field, users) {
  return {
    key: keyFor(field),
    label: field.label,
    description: descriptionFor(field),
    type: typeFor(field),
    options: optionsFor(field, users),
    validators: validatorsFor(field),
  }
}

function mapCompanyFields(groupFieldData) {
  return _.map(groupFieldData.groupFields, (field) => {
    return configureField(field, groupFieldData.users)
  })
}

function preProcessString(string) {
  return string.replace(/“|”/g, '"') //.replace(/‘|’/g, "'")
}

function attemptSanitizedMatch(value, choices) {
  if (!value) return null
  const specialCharRegEx = new RegExp(/[^a-zA-Z0-9]/g)
  let testValue = value

  //is there a wrapper of special characters
  const firstChar = value[0]
  const lastChar = value[value.length - 1]

  // if the chars are the same and they are both special chars
  // then its likely we have a wrapper. This will also catch wrapping spaces
  if (firstChar === lastChar && specialCharRegEx.test(firstChar)) {
    // remove the wrapper and leading/trailing whitespace
    testValue = testValue.substring(1, value.length - 1).trim()
    if (choices.includes(testValue)) return testValue
  }

  if (/‘|’/g.test(value)) {
    const newVal = value.replace(/‘|’/g, "'").trim()
    if (choices.includes(newVal)) return newVal
  }

  if (/\'/g.test(value)) {
    let newVal = value.replace(/\'/g, "‘").trim()
    if (choices.includes(newVal)) return newVal
    newVal = value.replace(/\'/g, "’").trim()
    if (choices.includes(newVal)) return newVal
  }

  // If we still don't have a match, nuke all special characters and remove whitespace
  testValue = testValue.replace(/[^a-zA-Z0-9]/g, "").trim()

  return choices.includes(testValue) ? testValue : null
}

function CSVtoArray(text) {
  //https://stackoverflow.com/questions/8493195/how-can-i-parse-a-csv-string-with-javascript-which-contains-comma-in-data
  const re_valid =
    /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/
  const re_value =
    /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g
  // Return NULL if input string is not well formed CSV string.

  if (!re_valid.test(text)) return null
  let a = [] // Initialize array to receive values.
  text.replace(re_value, function (m0, m1, m2, m3) {
    // Remove backslash from \' in single quoted values.
    if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"))
    // Remove backslash from \" in double quoted values.
    else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'))
    else if (m3 !== undefined) a.push(m3)
    return "" // Return empty string.
  })

  // Handle special case of empty last value.
  if (/,\s*$/.test(text)) a.push("")
  return a
}

function recordHookValidationsCompanies(record, fields) {
  if (!record || !fields || !fields.length) return record

  // Regex patterns
  const regexPatterns = {
    text: (maxChars) => new RegExp(`^.{0,${maxChars}}$`),
    date: /(((0[1-9]|1[012])[.\-\/](0[1-9]|[12][0-9]|3[01])[.\-\/](19|20)\d\d)|([12]\d{3}[.\-\/](0[1-9]|1[0-2])[.\-\/](0[1-9]|[12]\d|3[01])))/,
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-._~:\/?#[\]@!$&'()*+,;=.]+$/,
  }

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    const fieldKey = keyFor(field)
    const fieldUseCase = field.use_case
    const recordValue = record.get(fieldKey)

    if (!recordValue) continue

    const addError = (message) => record.addError(fieldKey, message)
    const addWarning = (message) => record.addWarning(fieldKey, message)

    // ===
    // Use Case Validations
    // ===

    // Number and Range
    if (
      fieldUseCase === FieldTypes.NUMBER ||
      fieldUseCase === FieldTypes.RANGE
    ) {
      const isNumber = StaticHelper.isNumber(recordValue)
      const value = isNumber ? parseFloat(recordValue) : recordValue

      if (!isNumber) {
        addError("Value must be a number")
        continue
      }

      if (
        value < NumericFieldLimits.LOWER ||
        value > NumericFieldLimits.UPPER
      ) {
        addError(
          `Value exceeds allowable numeric range ${NumericFieldLimitsHumanReadable.LOWER} - ${NumericFieldLimitsHumanReadable.UPPER}`
        )
      }

      if (fieldUseCase === FieldTypes.RANGE && String(value).includes(".")) {
        addError("Value must be an integer")
      }

      if (value < field.min_value || value > field.max_value) {
        addError(
          `Value must be a number within range: ${field.min_value} - ${field.max_value}`
        )
      }
    }

    // Checkbox
    else if (fieldUseCase === FieldTypes.CHECKBOX) {
      const values = CSVtoArray(preProcessString(recordValue))
      if (!values || !values.length) {
        if (recordValue.includes("'") || recordValue.includes('"')) {
          addWarning(
            `Value included a single or double quote which may cause parsing errors. Wrap your input in quotes for clarity.`
          )
        }
        addWarning(
          `Unable to match input "${recordValue}" due to invalid CSV formatting.`
        )
        continue
      }

      const fieldValues = flatMapCheckboxChoices(field.choices)
      const duplicateCheckboxChoices =
        detectDuplicateCheckboxChoices(fieldValues)

      const invalidValues = []
      const duplicateChoices = new Set()
      const validValues = new Set()

      for (let j = 0; j < values.length; j++) {
        const val = values[j].trim()

        if (duplicateCheckboxChoices.has(val)) {
          duplicateChoices.add(val)
        } else if (fieldValues.includes(val)) {
          validValues.add(val)
        } else {
          const sanitizedValue = attemptSanitizedMatch(val, fieldValues)
          if (sanitizedValue) validValues.add(sanitizedValue)
          else invalidValues.push(val)
        }
      }

      if (invalidValues.length) {
        addWarning(
          `Invalid choices: ${invalidValues.join(", ")}. These have been removed.`
        )
      }

      if (duplicateChoices.size) {
        addWarning(
          `Duplicate choices: ${Array.from(duplicateChoices).join(", ")}.`
        )
      }
    }

    // Text
    else if (fieldUseCase === FieldTypes.TEXT && field.max_characters) {
      const regex = regexPatterns.text(field.max_characters)
      if (!regex.test(recordValue)) {
        addError(`Character limit of ${field.max_characters} exceeded`)
      }
    }

    // Date
    else if (fieldUseCase === FieldTypes.DATE) {
      if (!regexPatterns.date.test(recordValue)) {
        addError(
          'Date format must match YYYY-MM-DD or MM-DD-YYYY. Valid separators include ".", "-", and "/".'
        )
      }
    }

    // ===
    // Attribute Specific Validations
    // ===

    if (field.attribute === "kt_url") {
      if (!regexPatterns.url.test(recordValue)) {
        addError("Must be a valid URL")
      }
    }
  }

  return record
}

function recordHookValidationsUsers(record) {
  if (!record) return record

  const fields = [
    "name",
    "email",
    "title",
    "username",
    "password",
    "permissions",
  ]

  // Regex patterns
  const regexPatterns = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // OLD regex - /\\S+@\\S+\\.\\S+/,
    password:
      /^$|(^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$)/,
    password_length: /^$|.{8,}/,
    password_whitespace: /^$|^(?!.* )/,
    password_uppercase: /^$|(?=.*?[A-Z])/,
    password_lowercase: /^$|(?=.*?[a-z])/,
    password_digit: /^$|(?=.*?[0-9])/,
    password_special_character: /^$|(?=.*?[#?!@$%^&*-])/,
    permissions: /^(\\s*|[0-2]{1})$/,
  }

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    const fieldKey = field
    const fieldAttribute = field
    const recordValue = record.get(field)

    if (!recordValue) continue

    const addError = (message) => record.addError(fieldKey, message)
    // const addWarning = (message) => record.addWarning(fieldKey, message)

    // ===
    // Attribute Specific Validations
    // ===

    // email
    if (fieldAttribute === "email") {
      if (!regexPatterns.email.test(recordValue)) {
        addError("Must be a valid email")
      }
    }

    // password"
    if (fieldAttribute === "password") {
      if (!regexPatterns.password.test(recordValue)) {
        addError("Invalid password")
      }
      if (!regexPatterns.password_length.test(recordValue)) {
        addError("- Password must be at least 8 characters")
      }
      if (!regexPatterns.password_whitespace.test(recordValue)) {
        addError("- Password must not contain whitespace")
      }
      if (!regexPatterns.password_uppercase.test(recordValue)) {
        addError("- Password must contain uppercase character")
      }
      if (!regexPatterns.password_lowercase.test(recordValue)) {
        addError("- Password must contain lowercase character")
      }
      if (!regexPatterns.password_digit.test(recordValue)) {
        addError("- Password must contain numerical digit")
      }
      if (!regexPatterns.password_special_character.test(recordValue)) {
        addError("- Password must contain non-alphanumeric character")
      }
    }

    // permissions
    if (fieldAttribute === "permissions") {
      if (!regexPatterns.permissions.test(recordValue)) {
        addError("Permissions must be an integer between 0-2")
      }
    }
  }

  return record
}

export {
  flatfileUseCases,
  flatfileSithOptions,
  addCompaniesConfig,
  addUsersConfig,
  userFields,
  mapCompanyFields,
  keyFor,
  recordHookValidationsCompanies,
  recordHookValidationsUsers,
}
